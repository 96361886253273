import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

import PurpleRow from "../components/purpleRow";
import MacBook from "../assets/images/MacBookPro-2.png";
import IPhone from "../assets/images/iphone-kid.png";
import OKSign from "../assets/images/feather.png";
import ChatIcon from "../assets/images/icons/chat.png";
import FiscalIcon from "../assets/images/icons/fiscal.png";
import PersonIcon from "../assets/images/icons/person.png";
import WriteIcon from "../assets/images/icons/write.png";
import LockIcon from "../assets/images/icons/lock.png";
import MultiIcon from "../assets/images/icons/multi.png";
import PhoneArrow from "../assets/images/icons/phone_arrows.png";
import FunctionSingle from "../components/FunctionSingle";

import FunctionAbsence from "../assets/images/absence_function.png";
import FunctionPayment from "../assets/images/payment_function.png";
import FunctionAutoPayment from "../assets/images/autopayment_function.png";

class FunctionsPage extends React.Component {
  openFunction(functionName, evt) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (document.getElementById(functionName)) {
      document.getElementById(functionName).style.display = "flex";
    }
    if (evt && evt.currentTarget) {
      evt.currentTarget.className += " active";
    }
  }

  render() {
    return (
      <Layout title="Zarządzanie przedszkolem dla zaawansowanych - funkcje">
        <div className="wrapper">
          <div className="section section-presentation">
            <div className="container">
              <div className="row">
                <div className="functions-main-wrapper">
                  <div className="col-md-6 functions-main-text">
                    <div>
                      <h2>Napisz do nas. Zawsze konkurencyjna oferta </h2>
                      <Link to={"/zamow"}>
                        <button className="violet-btn btn join-btn">
                          Wypróbuj bezpłatnie
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 hidden-sm">
                    <img className="ipad-phone-right" src={IPhone} />
                    <img
                      className="ipad-comp additional-ipad-comp"
                      src={MacBook}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section functions functions-padding how-it-works">
          <div className="container">
            <div className="row">
              <h2>Zobacz jak działa kidsup</h2>
              <p className="hiw-teaser">
                kidsup to elektroniczny system zarządzania przedszkolem, który
                pozwoli szybko i wygodnie komunikować się dyrekcji i
                nauczycielom z rodzicami maluchów oraz usprawni codzienną pracę
                placówki.
              </p>
              <div className="tab hiw-tab">
                <button
                  className="tablinks col-md-4 col-xs-4 active"
                  onClick={() => {
                    this.openFunction("chat");
                  }}
                >
                  <img src={ChatIcon} /> <br />
                  <span className="fn-title hidden-sm">
                    Szybka i wygodna komunikacja
                  </span>
                </button>
                <button
                  className="tablinks col-md-4 col-xs-4"
                  onClick={() => {
                    this.openFunction("payment");
                  }}
                >
                  <img src={FiscalIcon} /> <br />
                  <span className="fn-title hidden-sm">
                    Automatyczne płatności
                  </span>
                </button>
                <button
                  className="tablinks col-md-4 col-xs-4"
                  onClick={() => {
                    this.openFunction("absences");
                  }}
                >
                  <img src={PersonIcon} /> <br />
                  <span className="fn-title hidden-sm">
                    Rozliczanie nieobecności
                  </span>
                </button>
              </div>
              <FunctionSingle
                id="chat"
                image={FunctionPayment}
                title="Szybka i wygodna komunikacja"
                teaser="Szybka i wygodna komunikacja z rodzicami za sprawą tablicy informacyjnej oraz czatu. Rodzice w każdej chwili mogą dowiedzieć się, co robią ich pociechy. Wychowawcy mogą kontaktować się ze wszystkimi lub wybranymi rodzicami."
              />
              <FunctionSingle
                id="payment"
                image={FunctionAutoPayment}
                title="Automatyczne płatności"
                teaser="Wygodnie i bezbłędnie monitoruj zaległości w płatnościach, organizuj oraz rozliczaj jednorazowe i cykliczne opłaty, a także powiadamiaj rodziców o zaległościach. Udostępnij rodzicom przejrzyste rozliczenia i historię opłat."
              />

              <FunctionSingle
                id="absences"
                image={FunctionAbsence}
                title="Rozliczanie nieobecności"
                teaser="Rodzice mogą zgłaszać nieobecności dzieci za pomocą jednego
                    kliknięcia w kalendarz w aplikacji. Dyrekcja i wychowawcy
                    zyskują zautomatyzowany system do sprawnego i bezbłędnego
                    rozliczania nieobecności dzieci."
              />
            </div>
          </div>
        </div>

        <div className="section functions-padding functions-page">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12 functions-part ">
                <div>
                  <img src={WriteIcon} />
                </div>
                <p className="fn-title">Współdzielenie materiałów i plików</p>
                <p className="fn-teaser">
                  Udostępniaj i współdziel z rodzicami, dyrekcją oraz
                  nauczycielami ważne materiały dydaktyczne, dokumenty oraz
                  formularze. Zapewnij rodzicom łatwy dostęp do plików i
                  wyeliminuj ryzyko zgubienia istotnych dokumentów.
                </p>
              </div>
              <div className="col-md-4 col-sm-12 functions-part">
                <div>
                  <img src={LockIcon} />
                </div>
                <p className="fn-title">Pełne bezpieczeństwo danych</p>
                <p className="fn-teaser">
                  Zyskaj gwarancję bezpieczeństwa danych i wrażliwych
                  informacji. Połączenie z aplikacją jest szyfrowane, a dostęp
                  do niej mają wyłącznie zweryfikowani użytkownicy logujący się
                  za pomocą kodu PIN każdorazowo wysyłanego SMS-em.
                </p>
              </div>
              <div className="col-md-4 col-sm-12 functions-part">
                <div>
                  <img src={MultiIcon} />
                </div>
                <p className="fn-title">Dostęp z dowolnego urządzenia</p>
                <p className="fn-teaser">
                  Aplikacja kidsup pozwoli Ci wygodnie korzystać z
                  funkcjonalności niezależnie od tego, czy preferujesz pracę na
                  komputerze stacjonarnym, laptopie, tablecie czy smartfonie.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section functions opinion-section">
          <div className="container">
            <div className="row middle-flex middle-flex-col">
              <div className="col-xs-12 col-md-7">
                <div className="item active card card-min-margin ">
                  <div className="row hiw-faq-carousel-wrapper">
                    <div>
                      <p className="hiw-faq-title">
                        Najczęściej zadawane pytania
                      </p>

                      <div className="hiw-faq-part middle-flex">
                        <img
                          className="hiw-faq-part-icon"
                          src={OKSign}
                          alt=""
                        />
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">
                            Jak mogę wdrożyć system kidsup do swojej placówki?
                          </p>
                          <p className="hiw-faq-part-info">
                            Wdrożenie aplikacji do placówki następuje po
                            uprzednim skontaktowaniu się z nami i przeszkoleniu
                            z zakresu użytkowania naszego systemu. Zapraszamy do
                            kontaktu!
                          </p>
                        </div>
                      </div>
                      <div className="hiw-faq-part middle-flex">
                        <img
                          className="hiw-faq-part-icon"
                          src={OKSign}
                          alt=""
                        />
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">
                            Czy rodzice płacą za dostęp do aplikacji kidsup?
                          </p>
                          <p className="hiw-faq-part-info">
                            Nie, rodzice nie płacą za używanie aplikacji kidsup.
                          </p>
                        </div>
                      </div>

                      <div className="hiw-faq-part middle-flex">
                        <img
                          className="hiw-faq-part-icon"
                          src={OKSign}
                          alt=""
                        />
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">
                            Ile trzeba czekać na uruchomienie aplikacji w
                            placówce?
                          </p>
                          <p className="hiw-faq-part-info">
                            Placówka otrzymuje pełen dostęp do aplikacji kidsup
                            natychmiast po podpisaniu umowy o współpracy.
                          </p>
                        </div>
                      </div>

                      <div className="hiw-faq-part middle-flex">
                        <img
                          className="hiw-faq-part-icon"
                          src={OKSign}
                          alt=""
                        />
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">
                            Jak mogę pobrać aplikację kidsup?
                          </p>
                          <p className="hiw-faq-part-info">
                            Aplikację możesz pobrać w sklepie Google Play (dla
                            urządzeń z systemem Android) lub ze sklepu AppStore
                            (dla urządzeń z systemem iOS). Do systemu zalogujesz
                            się również na naszej stronie: www.kidsup.pl.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-5 functions-part from-home">
                <div>
                  <img src={PhoneArrow} />
                </div>
                <p className="fn-title">Zdalne nauczanie</p>
                <p className="fn-teaser">
                  Zyskaj wygodne i użyteczne narzędzie do komunikacji oraz
                  zdalnego nauczania i zapewnij stabilne funkcjonowanie
                  przedszkola niezależnie od obostrzeń gospodarczych.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="section opinion-section">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row">*/}
        {/*      <div className="col-sm-12">*/}
        {/*        <div className="main-desc opinion-wrapper">*/}
        {/*          <h2>Przeczytaj opinie właścicieli, dyrektorów i rodziców</h2>*/}
        {/*          <div className="row hiw-opinions-wrapper">*/}
        {/*            <div className="col-md-4 col-sm-12 no-padding-card">*/}
        {/*              <div className="item active card card-min-margin">*/}
        {/*                <div className="row hiw-opinion-carousel-wrapper">*/}
        {/*                  <div>*/}
        {/*                    <div className="main-opinion-carousel">*/}
        {/*                      Przede wszystkim szybki i uproszczony kontakt z*/}
        {/*                      rodzicami. Wysyłam jedną wiadomość i wiem, że*/}
        {/*                      trafi ona do każdego zarejestrowanego rodzica.*/}
        {/*                      Taka forma komunikacji sprawdziła się także*/}
        {/*                      podczas wiosennego nauczania zdalnego. Jako*/}
        {/*                      dyrektor miałam nadzór nad tym, jakie informacje i*/}
        {/*                      zadania zostały przekazane rodzicom.*/}
        {/*                    </div>*/}
        {/*                    <div className="main-opinion-carousel-author">*/}
        {/*                      <span className="bold-author">*/}
        {/*                        Alina Kaźmierczak*/}
        {/*                      </span>*/}
        {/*                      – Dyrektor w Przedszkolu Amica Kids*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-4 col-sm-12 no-padding-card">*/}
        {/*              <div className="item active card card-min-margin">*/}
        {/*                <div className="row hiw-opinion-carousel-wrapper">*/}
        {/*                  <div>*/}
        {/*                    <div className="main-opinion-carousel">*/}
        {/*                      Jako dyrektor przedszkola z czystym sumieniem mogę*/}
        {/*                      polecić aplikacje Kidsup. Aplikacja jest doskonałą*/}
        {/*                      formą kontaktu między przedszkolem, nauczycielami*/}
        {/*                      i rodzicami. Służy nam do przekazywania informacji*/}
        {/*                      na bieżąco, informowania o wysokości opłat za*/}
        {/*                      przedszkole. Bardzo fajną formą jest Tablica na*/}
        {/*                      której możemy umieszczać zdjęcia z życia*/}
        {/*                      przedszkola, dodawać informacje od wychowawców*/}
        {/*                      każdej grupy.*/}
        {/*                    </div>*/}
        {/*                    <div className="main-opinion-carousel-author">*/}
        {/*                      <span className="bold-author">*/}
        {/*                        Justyna Piątkowska*/}
        {/*                      </span>*/}
        {/*                      – Dyrektor Prywatnego Przedszkola Eko-Raj*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-4 col-sm-12 no-padding-card">*/}
        {/*              <div className="item active card card-min-margin">*/}
        {/*                <div className="row hiw-opinion-carousel-wrapper">*/}
        {/*                  <div>*/}
        {/*                    <div className="main-opinion-carousel">*/}
        {/*                      Dzięki tej aplikacji bardzo poprawiła się*/}
        {/*                      komunikacja z rodzicami. Nasza komunikacja*/}
        {/*                      przyspieszyła. Dla nas kidsup również działa jako*/}
        {/*                      system komunikacji ze wszystkimi pracownikami*/}
        {/*                      przedszkola. Myślę, że w trudnym obecnie czasie,*/}
        {/*                      gdybyśmy musieli prowadzić naukę zdalną, ta*/}
        {/*                      aplikacja znacznie ułatwiłaby pracę nauczycielkom.*/}
        {/*                    </div>*/}
        {/*                    <div className="main-opinion-carousel-author">*/}
        {/*                      <span className="bold-author">Wanda Tchoryk</span>*/}
        {/*                      – Dyrketor Przedszkola Publicznego nr 4 w Bielawie*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="section grey-background">
          <div className="container">
            <div className="row wyg">
              <div>
                <div className="main-desc">
                  <h2>Co zyskujesz?</h2>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-xs-12">
                <p className="wyg-faq-part-title">Płatności</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  automatyczne naliczanie opłat
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  rozliczanie wpłat od rodziców
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  możliwość tworzenia rachunków grupowych
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  wysyłka grupowa lub pojedyncza na (telefon/komputer/e-mail)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  filtrowanie i wysyłanie informacji o zaległościach
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  podgląd kto odczytał informację
                </p>
              </div>

              <div className="col-sm-6 col-md-4  col-xs-12">
                <p className="wyg-faq-part-title">Komunikacja</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  własna tablica
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  Posty zawierające informacje, zdjęcia oraz aktywne linki
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  e-nauczanie (zdalne)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  komunikaty do poszczególnych grup
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  komunikaty do wszystkich rodziców
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  komunikaty do wszystkich pracowników
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  pliki (Excel, World, PDF) do pobrania i wydruku
                </p>
              </div>
              <div className="col-sm-6 col-md-4  col-xs-12">
                <p className="wyg-faq-part-title">Personalizacja</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  własne logo
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  rejestr nieobecności (dzień, godzina)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  przyjmowanie nieobecności po terminie
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  ustawienie opłat (naliczanie stałe, dzienne, własne)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  indywidualne opłaty (per dziecko)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  numer rachunku bankowego
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  termin płatności
                </p>
              </div>
              <div className="col-sm-6 col-md-4 col-xs-12">
                <p className="wyg-faq-part-title">Nieobecności</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  system zgłaszania nieobecności
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  zgłaszanie nieobecności przez rodziców
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  dodanie nieobecności przez placówkę
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  potwierdzanie i anulowanie zgłoszenia
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  raport o nieobecności (ile zgłoszeń i ile jest aktualnie
                  dzieci)
                </p>
              </div>
              <div className="col-sm-6 col-md-4 col-xs-12">
                <p className="wyg-faq-part-title">Wsparcie</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  pełne wsparcie z naszej strony
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  szkolenie online
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  zdalne rozwiązywanie problemów rodziców
                </p>
              </div>
              <div className="col-sm-6 col-md-4 col-xs-12">
                <p className="wyg-faq-part-title">Platformy</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  Apple iOS (telefon)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  Android (telefon)
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  Aplikacja Webowa (komputer)
                </p>
              </div>
              <div className="col-sm-6 col-md-4 col-xs-12">
                <p className="wyg-faq-part-title">Raporty</p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  generowanie raportów płatniczych
                </p>
                <p className="wyg-faq-part-text hiw-faq-part">
                  <img className="hiw-faq-part-icon" src={OKSign} alt="" />
                  raporty miesięczne/ kwartalne / roczne
                </p>
              </div>
            </div>
          </div>
        </div>
        <PurpleRow>Wybierz lepszy sposób pracy</PurpleRow>
      </Layout>
    );
  }
}

export default FunctionsPage;
