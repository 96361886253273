import React from "react";

const FunctionSingle = ({ id, image, title, teaser }) => (
  <div id={id} className="tabcontent">
    <div className="col-md-6 col-sm-12 functions-part  hidden-sm hidden-xs">
      <div>
        <img src={image} alt="" className="singleFunctionImage" />
      </div>
    </div>
    <div className="col-md-6 col-sm-12 functions-part ">
      <p className="fn-title">{title}</p>
      <p className="fn-teaser">
        {teaser}
      </p>
    </div>
  </div>
);

export default FunctionSingle;
